/**
 * @type {{tiers: number[], poolAddress: string, poolName: string, token: {symbol: string, address: string, decimals: string, name: string}}}
 */
const goerliPool = {
  chainId: 5,
  poolAddress: "0x06BfA68d5a52792F79D6915D4F3f2caF7E1ee3e6",
  poolName: "Stripto",
  token: {
    address: "0x0b922ead77E16f0182681c44DC25bA73D6C95D23",
    name: "Stripto",
    symbol: "STRIP",
    decimals: "18",
  },
  tiers: [1, 2, 3],
};

/**
 * @type {{tiers: number[], poolAddress: string, poolName: string, token: {symbol: string, address: string, decimals: string, name: string}}}
 */
const bscPool = {
  chainId: 56,
  poolAddress: "0x142280dC26A2D1E285C9F14C7aC854Dd7cDa5D0B",
  poolName: "Stripto",
  token: {
    address: "0xa1AC3b22b102CaA62c9ecAf418585528855B0DdD",
    name: "Stripto",
    symbol: "STRIP",
    decimals: "18",
  },
  tiers: [1, 2, 3],
};

const striptoPools = {
  5: goerliPool,
  56: bscPool,
};
/**
 * @type {{factory: string, router: string, stripto: {symbol: string, address: string, decimals: number, name: string}, wbnb: {symbol: string, address: string, decimals: number, name: string}, usdt: {symbol: string, address: string, decimals: number, name: string}, wbnbUsdtLP: {symbol: string, address: string, decimals: number, name: string}, striptoWBNBLP: {symbol: string, address: string, decimals: number, name: string}}}
 */
export const goerliConstants = {
  router: "0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D",
  factory: "0x5C69bEe701ef814a2B6a3EDD4B1652CB9cc5aA6f",
  wbnb: {
    address: "0xB4FBF271143F4FBf7B91A5ded31805e42b2208d6",
    symbol: "WBNB",
    name: "Wrapped Ether",
    decimals: 18,
  },
  usdt: {
    address: "0xA61D4858b6171a47f5b6478Ce37bE643B52101Cf",
    symbol: "USDT",
    name: "Tether USD",
    decimals: 18,
  },
  stripto: {
    address: "0x0b922ead77E16f0182681c44DC25bA73D6C95D23",
    symbol: "STRIP",
    name: "Stripto",
    decimals: 18,
  },
  wbnbUsdtLP: {
    address: "0x2A5085C4c40a9Ce02f0a0C6851767Cf37756797D",
    symbol: "WBNB-USDT",
    name: "WBNB-USDT",
    decimals: 18,
  },
  striptoWBNBLP: {
    address: "0x143c6e59F1b7256CA244b7534827f204D97882de",
    symbol: "STRIP-WBNB",
    name: "STRIP-WBNB",
    decimals: 18,
  },
};

export const bscConstants = {
  router: "0x10ED43C718714eb63d5aA57B78B54704E256024E",
  factory: "0xcA143Ce32Fe78f1f7019d7d551a6402fC5350c73",
  wbnb: {
    address: "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
    symbol: "WBNB",
    name: "Wrapped Ether",
    decimals: 18,
  },
  usdt: {
    address: "0x55d398326f99059fF775485246999027B3197955",
    symbol: "USDT",
    name: "Tether USD",
    decimals: 18,
  },
  stripto: {
    address: "0xa1AC3b22b102CaA62c9ecAf418585528855B0DdD",
    symbol: "STRIP",
    name: "Stripto",
    decimals: 18,
  },
  wbnbUsdtLP: {
    address: "0x16b9a82891338f9bA80E2D6970FddA79D1eb0daE",
    symbol: "WBNB-USDT",
    name: "WBNB-USDT",
    decimals: 18,
  },
  striptoWBNBLP: {
    address: "0x1EFa952b4ac4C81D2b4171d99ccB21c2a0A22e95",
    symbol: "STRIP-WBNB",
    name: "STRIP-WBNB",
    decimals: 18,
  },
};

const chainConstants = {
  5: goerliConstants,
  56: bscConstants,
};

/**
 *
 * @param chainId
 * @returns {{factory: string, router: string, stripto: {symbol: string, address: string, decimals: number, name: string}, wbnb: {symbol: string, address: string, decimals: number, name: string}, usdt: {symbol: string, address: string, decimals: number, name: string}, wbnbUsdtLP: {symbol: string, address: string, decimals: number, name: string}, striptoWBNBLP: {symbol: string, address: string, decimals: number, name: string}}}
 */
export const getChainConstants = (chainId) => {
  return chainConstants[chainId];
};

export const supportedChains = [56, 1];

export const getPools = (chainId) => {
  if (supportedChains.includes(chainId)) {
    return striptoPools[chainId];
  } else {
    return null;
  }
};
